<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="tabClick">
            <el-tab-pane label="商品列表" name="list">
              <div class="mt10">
                <WarehouseDetail ref="detail" :search-config="searchConfig"/>
              </div>
            </el-tab-pane>
            <el-tab-pane label="分类查看" name="class">
              <WarehouseClassDetail ref="class" :search-config="searchConfig"/>
            </el-tab-pane>
          </el-tabs>
  </div>
</template>
<script>
import WarehouseDetail from "@/pages/dashboard/components/detailDialog/WarehouseDetail.vue";
import WarehouseClassDetail from "@/pages/dashboard/components/detailDialog/WarehouseClassDetail.vue";
export default {
  data() {
    return {
      searchConfig: {
        showSearch: true,
        showWare: true,
        showStatus: false,
      },
      outerVisible: false,
      activeName: "list"
    }
  },
  components: {
    WarehouseDetail,
    WarehouseClassDetail
  },
  mounted() {
    this.$refs.detail.queryParam.pageNum = 1
    this.$refs.detail.queryParam.page = 1
    this.$refs.detail.initList()
  },
  methods:{
    tabClick(e){
           this.activeName=e.name
           if (this.activeName==='class'){
              // this.$refs.class.queryParam.warehouseId=this.$refs.detail.queryParam.warehouseId
             this.$refs.class.queryParam.status=1
             this.$refs.class.initList()
           }else {
              //  this.$refs.class.queryParam.warehouseId=this.$refs.class.queryParam.warehouseId
              // this.$refs.class.queryParam.warehouseId = null
               this.$refs.detail.queryParam.pageNum = 1
               this.$refs.detail.queryParam.page = 1
               this.$refs.detail.initList()
           }
        },
  }
}
</script>
<style scoped>

</style>
